<template>
  <b-row>
    <b-col lg="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>期权交易持仓和成交额(行权日期) </b-card-title>
          <div class="selectContainer">
            <div class="chartSelect">
              <!-- <span>货币</span> -->
              <el-select v-model="Currency" placeholder="BTC">
                <el-option
                  v-for="item in currencyData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  @click.native="changeCurrency(item.value)"
                >
                </el-option>
              </el-select>
            </div>
            <div class="chartSelect">
              <!-- <span>行权价</span> -->
              <el-select v-model="strike" placeholder="All">
                <el-option
                  label="All"
                  value=""
                  @click.native="changeStrike('')"
                ></el-option>
                <el-option
                  v-for="item in StrikeData"
                  :key="item"
                  :label="item"
                  :value="item"
                  @click.native="changeStrike(item)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </b-card-header>
        <b-card-body>
          <option-position-chart ref="Expiry" />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col lg="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>期权交易持仓和成交额(行权价) </b-card-title>
          <div class="selectContainer">
            <div class="chartSelect">
              <!-- <span>货币</span> -->
              <el-select v-model="priceCurrency" placeholder="BTC">
                <el-option
                  v-for="item in currencyData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  @click.native="priceChangeCurrency(item.value)"
                >
                </el-option>
              </el-select>
            </div>
            <div class="chartSelect">
              <!-- <span>行权日期</span> -->
              <el-select v-model="expiration_timestamp" placeholder="All">
                <el-option
                  label="All"
                  value=""
                  @click.native="changeTime('')"
                ></el-option>
                <el-option
                  v-for="item in EtData"
                  :key="item"
                  :label="item"
                  :value="item"
                  @click.native="changeTime(item)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </b-card-header>
        <b-card-body>
          <option-position-chart ref="strikePrice" />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col lg="12">
      <b-card no-body>
        <b-card-header class="Instrument">
          <b-card-title>期权交易持仓量(标的) </b-card-title>
          <div class="selectContainer">
            <div class="chartSelect selectInstrument">
              <b>标的名</b>
              <el-select
                v-model="instrument_name"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  @click.native="getCurrencyChartData(item.value)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </b-card-header>
        <b-card-body>
          <option-position-chart ref="currency" :chartType="3" />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { getBtcExpiry, getBtcPrice, getBtcCurrency } from "@/api/Options.js";
import {
  getBtcOptionsRate,
  getEthOptionsRate,
} from "@/api/BlockTransactions.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import optionPositionChart from "@/views/charts-and-maps/charts/echart/option-echart/optionPositionChart.vue";
import { avatarText } from "@/@core/utils/filter";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    optionPositionChart,
  },
  data() {
    return {
      ScreenName: "",
      defaultInstrument: "", //当前in标的名
      testA: "",
      ScreenTime: "",
      ScreenStrike: "",
      Currency: "BTC",
      priceCurrency: "BTC",
      strike: "",
      expiration_timestamp: "",
      instrument_name: "",
      option_type: "",
      creation_timestamp: "",
      currencyData: [
        {
          value: "BTC",
          label: "BTC",
        },
        {
          value: "ETH",
          label: "ETH",
        },
      ],
      StrikeData: [],
      EtData: [],
      pickerOptions: {
        disabledDate(time) {
          // 禁止选择过去日期（可以选择今天）
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },

      //表一
      ExpirytempData: [], //存放表一行权日期所有数据
      ExpiryTime: [], //存放表一x轴所有时间
      timeData: [],
      totalData: [],
      callData: [], //存放表一y轴call所有数据
      putlData: [], //存放表一y轴put所有数据
      oldOptions: [],
      strike: [],

      //表二
      PricetempData: [], //存放表二行权日期所有数据
      PriceXData: [], //存放表二x轴所有行权价
      PricetimeData: [],
      totalPriceData: [],
      callPriceData: [], //存放表二y轴call所有数据
      putlPriceData: [], //存放表二y轴put所有数据
      strikLineData: [
        500, 600, 800, 1212, 1658, 500, 80, 1800, 80, 700, 652, 1520, 500, 600,
        800, 1212, 1658, 500, 80, 1800, 80, 700, 652, 1520, 500, 600, 800, 1212,
        1658, 500, 80, 1800, 80, 700, 652, 1520, 500, 600, 800, 1212, 1658, 500,
        80, 1800, 80, 700, 652, 1520, 500, 600, 800, 1212, 1658, 500, 80, 1800,
        80, 700, 652, 1520,
      ], //痛点data数据
      strikPut: [],
      strikCall: [],
      minStrik: "",

      //表三
      CurrencytempData: [], //存放表三所有数据
      CurrencyTime: [], //存放表三x轴所有时间
      CurrencytimeData: [],
      inyData: [],
      instrumentsData: [], //存放表三y轴所有数据
      inData: "",

      options: [],
      value: [],
      list: [],
      loading: false,
      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      BtcStates: [],
      EthStates: [],
      BtcStates: [],
      // st: "2021-12-01",
      // et: "2022-01-19",
    };
  },
  created() {
    this.getExpiryChartData();
    this.getPriceChartData();
    this.getCurrencyChartData();
    this.getStatesData();
    this.getEthListData();
    // this.concatStates();
  },
  computed: {},
  mounted() {},
  methods: {
    //数据显示格式化千分位
    formatNum(value) {
      if (!value && value !== 0) return 0;
      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    // 初始化 行权日期 图表数据
    getExpiryChartData(params) {
      getBtcExpiry(params)
        .then((res) => {
          let ExpirytempData = res.data.data;

          //获取所有行权价
          this.StrikeData = res.data.strike;

          //获取所有得时间
          let timeData = this.getExpiryTime(ExpirytempData);
          // console.log("timeData:", timeData);
          //对所有时间进行格式化
          let set = new Set();
          for (let i = 0; i < timeData.length; i++) {
            let day =
              new Date(timeData[i]).getDate() < 10
                ? "0" + new Date(timeData[i]).getDate()
                : new Date(timeData[i]).getDate();
            set.add(new Date(timeData[i]).getMonth() + 1 + "-" + day);
          }
          this.ExpiryTime = Array.from(set);
          // console.log("123", this.ExpiryTime);

          //获取call put 所有数据
          let totalData = this.getExpiryValue(ExpirytempData);
          // console.log("totalData:", totalData);
          this.callData = [];
          this.putlData = [];
          for (let i = 0; i < totalData.length; i++) {
            let item = totalData[i];
            this.callData.push(parseFloat(item.call));
            this.putlData.push(parseFloat(item.put));
          }

          this.chartExpiryData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //初始化 行权价 图表数据
    getPriceChartData(params) {
      getBtcPrice(params)
        .then((res) => {
          let PricetempData = res.data.data;

          let arr = new Array();
          for (let i = 0; i < res.data.et.length; i++) {
            let day = this.dateFtt("yyyy-MM-dd", new Date(res.data.et[i]));
            arr.push(day);
          }
          this.EtData = arr;

          //获取所有得x轴数据
          this.PriceXData = this.getExpiryTime(PricetempData);
          // console.log("PriceXData:", this.PriceXData);
          //对所有时间进行格式化

          //获取call put 所有数据
          let totalPriceData = this.getExpiryValue(PricetempData);
          //console.log("totalPriceData:", totalPriceData);
          this.callPriceData = [];
          this.putlPriceData = [];
          for (let i = 0; i < totalPriceData.length; i++) {
            let item = totalPriceData[i];
            this.callPriceData.push(parseFloat(item.call));
            this.putlPriceData.push(parseFloat(item.put));
          }
          let PriceXDataNew = this.PriceXData;
          let callPriceDataNew = this.callPriceData;
          let putlPriceDataNew = this.putlPriceData;

          //处理点击时间筛选显示痛点k线data数据

          //strikPrice数据
          // this.strikLineData = [];
          if (
            this.expiration_timestamp &&
            this.expiration_timestamp.length !== 0
          ) {
            this.strikData(PriceXDataNew, callPriceDataNew, putlPriceDataNew);
          } else {
            this.strikLineData = [];
          }

          // 获取图表数据xy轴
          this.chartPriceData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //初始化 标的 图表数据
    getCurrencyChartData(val) {
      let params = {
        in: this.instrument_name,
      };
      this.ScreenName = val;
      getBtcCurrency(params)
        .then((res) => {
          let CurrencytempData = res.data.data;

          this.defaultInstrument = res.data.in;

          //获取x轴所有时间
          let CurrencytimeData = this.getExpiryTime(CurrencytempData);

          //格式化x轴所有时间
          let arr = new Array();
          for (let i = 0; i < CurrencytimeData.length; i++) {
            let day = this.dateFtt(
              "MM-dd hh:mm",
              new Date(CurrencytimeData[i])
            );
            arr.push(day);
          }
          this.CurrencyTime = arr;

          //获取y轴所有data值
          let instrumentsData = this.getExpiryValue(CurrencytempData);
          this.inyData = [];
          for (let i = 0; i < instrumentsData.length; i++) {
            let item = instrumentsData[i];
            this.inyData.push(parseFloat(item));
          }

          // 获取图表数据xy轴
          this.chartCurrencyData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取Btc标的 所有标的名
    getStatesData(value) {
      getBtcOptionsRate()
        .then((res) => {
          this.BtcStates = [...res.data.in];
          //console.log("this.BtcStates", this.BtcStates.length);

          // 获取图表数据xy轴
          //this.chartCurrencyData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取Eth标的 所有标的名
    getEthListData(value) {
      getEthOptionsRate()
        .then((res) => {
          if (res.data.code == 0) {
            this.EthStates = res.data.in;
            //console.log("this.EthStates", this.EthStates);
          }
        })
        .catch((err) => {
          this.isShow = true;
          //console.log("没有数据")
        });
    },

    //获取 行权日期 chart XY轴data值
    chartExpiryData(type) {
      let ScreenStrike =
        this.strike && this.strike.length !== 0 ? this.ScreenStrike : "All";
      let Currency = this.Currency;
      if (type === 1) {
        this.$refs.Expiry.chart.setOption({
          legend: {
            data: ["看涨持仓总量", "看跌持仓总量"],
            textStyle: {
              color: "#FFF",
            },
            left: "70%",
          },
          tooltip: {
            align: "axis",
            formatter: function (params) {
              let str = params[0].value.toString();
              let str2 = params[1].value.toString();
              let reg =
                str.indexOf(".") > -1
                  ? /(\d)(?=(\d{3})+\.)/g
                  : /(\d)(?=(?:\d{3})+$)/g;
              let reg1 =
                str2.indexOf(".") > -1
                  ? /(\d)(?=(\d{3})+\.)/g
                  : /(\d)(?=(?:\d{3})+$)/g;
              return params[0].value
                ? `<div>${params[0].name}</div>
                <div> ${ScreenStrike} <br><br> 看涨持仓总量:${str.replace(
                    reg,
                    "$1,"
                  )} <br><br> 看跌持仓总量:${str2.replace(reg1, "$1,")}</div> `
                : ``;
            },
          },
          xAxis: {
            data: this.ExpiryTime,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: function (value) {
                let num = value.toString();
                let reg =
                  num.indexOf(".") > -1
                    ? /(\d)(?=(\d{3})+\.)/g
                    : /(\d)(?=(?:\d{3})+$)/g;
                return ` ${num.replace(reg, "$1,")}  ${Currency}`;
              },
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0,
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          series: [
            {
              name: "看涨持仓总量",
              data: this.callData,
              type: "bar",
              itemStyle: {
                color: "#2EBD85",
              },
            },
            {
              name: "看跌持仓总量",
              data: this.putlData,
              type: "bar",
              itemStyle: {
                color: "#E0294A",
              },
            },
          ],
        });
      }
    },
    //获取 行权价 chart XY轴data值
    chartPriceData(type) {
      let ScreenTime =
        this.expiration_timestamp && this.expiration_timestamp.length !== 0
          ? this.ScreenTime
          : "All";
      let Currency = this.Currency;
      if (type === 1) {
        this.$refs.strikePrice.chart.setOption({
          legend: {
            data: [
              "看涨持仓总量",
              "看跌持仓总量",
              "Total Intrinsic Value",
              "Call",
              "Put",
            ],
            selected: {
              Call: false, //图例为‘Call’的一项默认置灰
              Put: false, //图例为‘Call’的一项默认置灰
            },
            textStyle: {
              color: "#FFF",
            },
            left: "65%",
          },
          tooltip: {
            align: "axis",
            formatter: function (params) {
              if (params.length == 2) {
                return params[0].value
                  ? `<div>${params[0].name}</div>
                <div> ${ScreenTime} <br><br> 看涨持仓总量:${params[0].value} <br><br> 看跌持仓总量:${params[1].value}</div> `
                  : `<div>${params[0].name}</div>
                <div> ${ScreenTime} <br><br> 看涨持仓总量:${params[0].value} <br><br> 看跌持仓总量:${params[1].value}</div> `;
              } else {
                let str = params[2].value.toString();
                let reg =
                  str.indexOf(".") > -1
                    ? /(\d)(?=(\d{3})+\.)/g
                    : /(\d)(?=(?:\d{3})+$)/g;
                return params[0].value
                  ? `<div>${params[0].name}</div>
                <div> ${ScreenTime} <br><br> 看涨持仓总量:${
                      params[0].value
                    } <br><br> 看跌持仓总量:${
                      params[1].value
                    }<br><br> Total Intrinsic Value: $${str.replace(
                      reg,
                      "$1,"
                    )}</div> `
                  : `<div>${params[0].name}</div>
                <div> ${ScreenTime} <br><br> 看涨持仓总量:${
                      params[0].value
                    } <br><br> 看跌持仓总量:${
                      params[1].value
                    }<br><br> Total Intrinsic Value: $${str.replace(
                      reg,
                      "$1,"
                    )}</div> `;
              }
            },
          },
          xAxis: {
            type: "category",
            data: this.PriceXData,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (value) {
                  let num = value.toString();
                  let reg =
                    num.indexOf(".") > -1
                      ? /(\d)(?=(\d{3})+\.)/g
                      : /(\d)(?=(?:\d{3})+$)/g;
                  return ` ${num.replace(reg, "$1,")}  ${Currency}`;
                },
                textStyle: {
                  color: "#FFF",
                },
              },
              splitLine: {
                lineStyle: {
                  opacity: 0,
                },
              },
            },
            {
              type: "value",
              axisLabel: {
                formatter: "{value} M",
              },
            },
          ],
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          series: [
            {
              name: "看涨持仓总量",
              data: this.callPriceData,
              type: "bar",
              itemStyle: {
                color: "#2EBD85",
              },
            },
            {
              name: "看跌持仓总量",
              data: this.putlPriceData,
              type: "bar",
              itemStyle: {
                color: "#E0294A",
              },
            },
            {
              name: "Total Intrinsic Value",
              data: this.strikLineData,
              yAxisIndex: 1,
              type: "line",
              showSymbol: true,
              symbol: "circle",
              itemStyle: {
                color: "#EFC394",
              },
              lineStyle: {
                width: 0, // 正常时的折线宽度
              },
              emphasis: {
                lineStyle: {
                  width: 1, // hover时的折线宽度
                },
              },
              barWidth: 6,
              //横轴标记刻度线
              markLine: {
                symbol: ["none", "none"],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: "dashed",
                      color: "#EFC394",
                    },
                    label: {
                      show: true,
                      position: "end",
                      distance: 20,
                      textStyle: {
                        color: "#EFC394",
                        fontSize: 14,
                      },
                      formatter: "Max Pain Price: {c}",
                      // formatter: function () {
                      //   return "Max Pain Price: " + ;
                      // },
                    },
                  },
                },
                data: [
                  {
                    xAxis: this.testA,
                  },
                ],
              },
            },
            {
              name: "Call",
              data: this.strikCall,
              yAxisIndex: 1,
              type: "line",
              showSymbol: true,
              symbol: "circle",
              itemStyle: {
                color: "#2EBD85",
              },
              lineStyle: {
                width: 0, // 正常时的折线宽度
              },
              emphasis: {
                lineStyle: {
                  width: 1, // hover时的折线宽度
                },
              },
              barWidth: 6,
            },
            {
              name: "Put",
              data: this.strikPut,
              yAxisIndex: 1,
              type: "line",
              showSymbol: true,
              symbol: "circle",
              itemStyle: {
                color: "#E0294A",
              },
              lineStyle: {
                width: 0, // 正常时的折线宽度
              },
              emphasis: {
                lineStyle: {
                  width: 1, // hover时的折线宽度
                },
              },
              barWidth: 6,
            },
          ],
        });
      }
    },
    //获取 标的 chart XY轴data值
    chartCurrencyData(type) {
      let defaultInstrument = this.defaultInstrument;
      if (type === 1) {
        let ScreenName =
          this.instrument_name && this.instrument_name.length !== 0
            ? this.ScreenName
            : this.defaultInstrument;
        this.$refs.currency.chart.setOption({
          tooltip: {
            align: "axis",
            formatter: function (params) {
              let str = params[0].value.toString();
              let reg =
                str.indexOf(".") > -1
                  ? /(\d)(?=(\d{3})+\.)/g
                  : /(\d)(?=(?:\d{3})+$)/g;
              return params[0].value
                ? `<div>${params[0].name}</div>
                <div> ${ScreenName} <br><br> 持仓总量：${str.replace(
                    reg,
                    "$1,"
                  )}</div> `
                : `<div>${params[0].name}</div>
                <div> ${ScreenName} <br><br> 持仓总量：${str.replace(
                    reg,
                    "$1,"
                  )}</div> `;
            },
          },
          xAxis: {
            data: this.CurrencyTime,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: function (value) {
                let num = value.toString();
                let reg =
                  num.indexOf(".") > -1
                    ? /(\d)(?=(\d{3})+\.)/g
                    : /(\d)(?=(?:\d{3})+$)/g;
                return ` ${num.replace(reg, "$1,")}  ${ScreenName.substring(
                  0,
                  3
                )}`;
              },
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0,
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 95,
              end:100,
            },
            {
              start:95,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          series: [
            {
              name: "持仓总量",
              data: this.inyData,
              type: "bar",
              itemStyle: {
                color: "#2EBD85",
              },
            },
          ],
        });
      }
    },

    //获取对象,数组所有属性键值(key)
    getExpiryTime(object) {
      var keys = [];
      for (var property in object) keys.push(property);
      return keys;
    },
    //获取对象,数组所有属性对应值(value)
    getExpiryValue(object) {
      var values = [];
      for (var property in object) values.push(object[property]);
      return values;
    },
    //时间格式化
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    //标的图表搜索关键词 传入一个remote-method函数，在输入值发生变化时调用，参数为当前输入值
    remoteMethod(query) {
      this.states = [];
      for (let i = 0; i < this.BtcStates.length; i++) {
        this.states.push(this.BtcStates[i]);
      }
      for (let i = 0; i < this.EthStates.length; i++) {
        this.states.push(this.EthStates[i]);
      }
      //this.states= this.BtcStates.map((date,i) => ({date, data: this.EthStates[i]}));
      //console.log("-----",this.states);

      this.list = this.states.map((item) => {
        return { value: `${item}`, label: `${item}` };
      });
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },

    //给行权日期 货币 下拉筛选绑定事件，传递参数监听点击并触发
    changeCurrency() {
      let params = {
        bc: this.Currency,
      };
      this.strike = "";
      this.getExpiryChartData(params);
    },
    //给行权日期 行权价 下拉筛选绑定事件，传递参数监听点击并触发
    changeStrike(value) {
      let params = {
        bc: this.Currency,
        strike: this.strike,
      };
      this.ScreenStrike = value;
      // this.Currency = "";
      this.getExpiryChartData(params);
    },
    //给行权价 货币 下拉筛选绑定事件，传递参数监听点击并触发
    priceChangeCurrency(value) {
      let params = {
        bc: this.priceCurrency,
      };
      this.expiration_timestamp = "";
      this.Currency = value;
      this.getPriceChartData(params);
    },
    //行权价 行权日期 下拉选项卡改变日期时间参数转成时间戳
    changeTime(value) {
      let dateTotime;
      let params;
      //console.log("value---", value);
      if (value != "") {
        dateTotime = `${
          new Date(new Date(this.expiration_timestamp)).getTime() / 1000
        }`;
        params = {
          bc: this.Currency,
          et: dateTotime,
        };
      } else {
        //console.log("select all....");
        this.strikLineData = [];
        this.strikPut = [];
        this.strikCall = [];
        this.testA = [];
      }

      this.ScreenTime = value;
      this.getPriceChartData(params);
    },

    //计算出每个行权价call，put值相加得出痛点data数据
    strikData(PriceXDataNew, callPriceDataNew, putlPriceDataNew) {
      this.strikCall = [];
      this.strikPut = [];
      // console.log("callPriceDataNew:", callPriceDataNew);
      // console.log("putlPriceDataNew:", putlPriceDataNew);
      // let beforeSum = 0;
      //处理看涨数据
      for (let i = 0; i < PriceXDataNew.length; i++) {
        // 第一个不需要进行相加或相减
        if (i == 0) {
          this.strikCall.push(callPriceDataNew[i]);
        } else {
          // 第二条数据开始
          let beforeArray = [];
          let callAdd = 0;
          for (let j = 0; j <= i; j++) {
            if (j == 0) {
              callAdd =
                (PriceXDataNew[i] - PriceXDataNew[j]) * callPriceDataNew[j];
              //console.log("---PriceXDataNew["+i+"]:"+PriceXDataNew[i]+" - PriceXDataNew["+j+"]:"+PriceXDataNew[j]+" * callPriceDataNew["+j+"]:"+callPriceDataNew[j]+",before="+callAdd+"");
            }
            if (j > 1) {
              callAdd =
                (PriceXDataNew[i] - PriceXDataNew[j - 1]) *
                callPriceDataNew[j - 1];
              //console.log("PriceXDataNew["+i+"]:"+PriceXDataNew[i]+" - PriceXDataNew["+(j-1)+"]:"+PriceXDataNew[j-1]+" * callPriceDataNew["+(j-1)+"]:"+callPriceDataNew[j-1]+",before="+callAdd+"");
            }
            beforeArray.push(callAdd);
          }
          let callSum = 0;
          for (let s = 0; s < beforeArray.length; s++) {
            callSum += beforeArray[s];
          }
          this.strikCall.push(callSum);
        }
      }
      //console.log("this.strikCall:", this.strikCall);

      //处理看跌数据
      for (let i = 0; i < PriceXDataNew.length; i++) {
        let putArray = [];
        let putAdd = 0;
        for (let j = i + 1; j < PriceXDataNew.length; j++) {
          putAdd = (PriceXDataNew[j] - PriceXDataNew[i]) * putlPriceDataNew[j];
          //console.log("priceData[" + j + "]:" + priceData[j] + " - priceData[" + i + "]:" + priceData[i] + " * putData[" + j + "]:" + putData[j] + " = " + sum + "");
          putArray.push(putAdd);
          // console.log("beforeArray", beforeArray);
        }
        let putSum = 0;
        for (let s = 0; s < putArray.length; s++) {
          putSum += putArray[s];
        }
        this.strikPut.push(putSum);
      }
      //console.log("this.strikPut:", this.strikPut);

      let arr1 = this.strikCall;
      let arr2 = this.strikPut;
      //console.log("1", arr1);
      //console.log("2", arr2);
      this.totalStrik = [];

      this.totalStrik = arr1.map(function (index, item) {
        return index + arr2[item];
      });

      //console.log("this.totalStrik", this.totalStrik);
      //痛点最小值
      this.minStrik = Math.min(...this.totalStrik);
      let iii = this.totalStrik.indexOf(this.minStrik);
      //console.log("iii:", iii);
      this.testA = this.PriceXData[parseInt(iii)];
      //console.log("this.PriceXDataNew", this.PriceXData);
      //console.log("minStrik", this.minStrik);
      this.strikLineData = this.totalStrik;

      //数据格式化千分位
      // for (let i = 0; i < this.strikLineData.length; i++) {
      //   this.strikLineData[i] = this.formatNum(this.strikLineData[i]);
      // }
    },
  },
};
</script>
<style lang="scss">
.selectContainer {
  width: 250px;
  display: flex;
  justify-content: flex-end;
  .chartSelect {
    display: flex;
    flex-direction: row;
    padding-right: 5px;
     width: 160px;
    color: #ffff !important;
    b {
      width:50px;
      margin: 5px 5px;
    }
    .el-select {
      height: 30px;
      width: 100%;
      .el-input {
        color: #000000 !important;
        .el-input__suffix {
          .el-input__suffix-inner {
            .el-input__icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .el-input__inner {
          background-color: rgba(159, 159, 159, 0.1);
          border: none;
          //border-radius: 15px;
          color: #ffff;
          height: 30px;
        }
        .el-input__inner:focus {
          border-color: #EFC394 !important;
        }
        .el-input__suffix {
          .el-input__suffix-inner {
            .el-select__caret {
              color: #ffff !important;
              height: 30px;
              cursor: auto;
            }
          }
        }
      }
    }

    .el-date-editor {
      .el-input__inner {
        height: 30px;
      }
      .el-input__prefix {
        .el-input__icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .selectInstrument {
    display: flex;
    flex-direction: row;
    width: 300px;
    b {
      width: 50px;
    }
    .el-select {
      width: 200px;
      .el-input {
        .el-input__inner {
          border-color: #ffff;
        }
        .el-input__inner:focus {
          border-color: #EFC394 !important;
        }
        .el-input__suffix {
          .el-input__suffix-inner {
            .el-select__caret {
              color: #ffff !important;
            }
          }
        }
      }
    }
  }
}
// span {

//   display: flex !important;
//   justify-content: flex-end;
//   padding-right: 4%;

// }
.card-title {
  color: #ffff !important;
}
</style>
