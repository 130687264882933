<template>
  <div
    ref="optionPositionChart"
    :style="{ width: '100%', height: '300px' }"
  ></div>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    title: {
      type: String,
    },
    chartType: {
      type: Number,
    },
  },
  data() {
    return {
      //定义变量来装图表对象
      chart: null,
      series: [
        {
          name: "看涨持仓总量",
          data: [],
          type: "bar",
          itemStyle: {
            color: "#2EBD85",
          },
        },
        {
          name: "看跌持仓总量",
          data: [],
          type: "bar",
          itemStyle: {
            color: "#E0294A",
          },
        },
      ],
      series2: [
        {
          name: "看涨持仓总量",
          data: [],
          type: "bar",
          itemStyle: {
            color: "#2EBD85",
          },
        },
      ],
    };
  },
  mounted() {
    this.getEchartData();
    //图表自适应大小
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      const eChart = this.$refs.optionPositionChart;
      this.chart = echarts.init(eChart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [],
          textStyle: {
            color: "#FFF",
          },
          left: "70%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            textStyle: {
              color: "#FFF",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value} 万 BTC",
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
               opacity: 0,
              },
            },
          },
          {
            type: "value",
            axisLabel: {
              formatter: "{value} BTC",
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              lineStyle: {
                 opacity: 0,
              },
            },
          },
        ],
        
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        graphic: [
          {
            type: "image",
            id: "logo",
            right: "center",
            bottom: "center",
            z: 0,
            bounding: "all",
            style: {
              image:
                "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
              width: 400,
              height: 68,
            },
          },
        ],
        series: this.chartType === 3 ? this.series2 : this.series,
      };
      // 设置配置项
      this.chart.setOption(option);
    },
  },
};
</script>
<style lang="scss">
</style>
